.scroll__top {
	color: #e9f3f0;
	background-color: transparent;
	visibility: hidden;
	border-radius: 50%;
	opacity: 0;
	border: 1px solid #d7e1de;
	position: fixed;
	bottom: 400px;
	right: 5%;
	outline: none;
	transition: opacity 0.4s;
	z-index: 1111;
	height: 50px;
    width: 50px;
}
.scroll__top__visible {
	visibility: visible;
	opacity: 1;
}
@media (max-width: 1024px) {
	.scroll__top {
		right: 75px;
	}
}
@media (max-width: 992px) {
	.scroll__top {
		right: 0px;
		padding: 8px;
		bottom: 115px;
	}
}
@media (max-width: 576px) {
	.scroll__top {
		display: none;
		bottom: 115px;
	}
}
@media (min-width: 1600px) {
	.scroll__top {
		right: 4%;
		bottom: 400px;
	}
}